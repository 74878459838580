<template>
    <div class="formGroup mb-8 last:mb-0">
        <div
            v-if="label"
            class="mb-3"
        >
            <label
                :for="name"
                class="text-2xl inline-block"
            >
                {{ label }}
            </label>
        </div>

        <slot />
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: null
        },

        name: {
            type: String,
            default: null
        }
    }
};
</script>
