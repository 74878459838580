import * as API from '../API.js';

export default {
    all () {
        return API.apiClient.get('/admin/classrooms');
    },

    get (classroomId) {
        return API.apiClient.get(`/admin/classrooms/${classroomId}`);
    },

    create (payload) {
        return API.apiClient.post('/admin/classrooms', payload);
    },

    update (classroomId, payload) {
        return API.apiClient.put(`/admin/classrooms/${classroomId}`, payload);
    },

    remove (classroomId) {
        return API.apiClient.delete(`/admin/classrooms/${classroomId}`);
    }
};
