<template>
    <div class="mb-4 flex items-center justify-between">
        <h2 class="text-4xl font-semibold leading-tight mb-0">
            {{ heading }}
        </h2>

        <div
            v-if="addNewUrl"
            class="w-48"
        >
            <Button
                :to="addNewUrl"
                label="Lägg till"
            />
        </div>
    </div>
</template>

<script>
import Button from './Button';

export default {
    components: {
        Button
    },

    props: {
        heading: {
            type: String,
            required: true
        },

        addNewUrl: {
            type: String,
            default: null
        }
    }
};
</script>
